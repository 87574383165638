/** @jsxImportSource @emotion/react */
import {
  addLayer,
  useLayersCollection,
  toggleLayerVisibility,
  removeLocationFromLayer,
  updateLayer,
  addLocationToLayer
} from '../firestore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import {
  Add,
  Clear,
  Edit,
  FileUpload,
  MoreVert,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import {
  deleteDoc,
  DocumentSnapshot,
  getDoc,
  QueryDocumentSnapshot
} from 'firebase/firestore';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AuthContext, AuthStatus } from '../contexts/AuthContext';
import { Coordinate, Layer, Location } from '../firestore/types';
import { notUndefinedOrNull } from '../helpers/utility';
import { useRecoilValue } from 'recoil';
import { googleMapsState, mapState } from '../atoms/googleMaps';

enum ModifyLayerAction {
  EDIT_LAYER = 'EDIT_LAYER',
  IMPORT_LOCATIONS = 'IMPORT_LOCATIONS'
}

interface AddLayerForm {
  layerName: string;
}

interface ImportLocationsForm {
  csvFile: FileList;
}

function LayerItem({
  layerDoc,
  selectedLayer,
  setSelectedLayer,
  setModifyLayerAction,
  setLayerToModify
}: {
  layerDoc: QueryDocumentSnapshot<Layer>;
  selectedLayer: DocumentSnapshot<Layer> | undefined;
  setSelectedLayer: (layer: DocumentSnapshot<Layer> | undefined) => void;
  setModifyLayerAction: (action: ModifyLayerAction) => void;
  setLayerToModify: (layer: DocumentSnapshot<Layer>) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ListItemButton
        selected={selectedLayer?.id === layerDoc.id}
        onClick={() => setSelectedLayer(layerDoc)}
      >
        <ListItemText primary={layerDoc.data().name} />
        <IconButton onClick={() => toggleLayerVisibility(layerDoc)}>
          {layerDoc.data().visibility ? <Visibility /> : <VisibilityOff />}
        </IconButton>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleClose();
              setModifyLayerAction(ModifyLayerAction.EDIT_LAYER);
              setLayerToModify(layerDoc);
            }}
            key={`${layerDoc.id}-edit`}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Edit layer</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setModifyLayerAction(ModifyLayerAction.IMPORT_LOCATIONS);
              setLayerToModify(layerDoc);
            }}
            key={`${layerDoc.id}-import`}
          >
            <ListItemIcon>
              <FileUpload />
            </ListItemIcon>
            <ListItemText>Import CSV</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (selectedLayer?.id === layerDoc.id) {
                setSelectedLayer(undefined);
              }
              handleClose();
              deleteDoc(layerDoc.ref);
            }}
            key={`${layerDoc.id}-delete`}
          >
            <ListItemIcon>
              <Clear />
            </ListItemIcon>
            <ListItemText>Delete layer</ListItemText>
          </MenuItem>
        </Menu>
      </ListItemButton>
      <List
        css={css`
          border-left: solid 8px ${layerDoc.data().color};
        `}
        dense
      >
        {layerDoc.data().locations.length > 0 ? (
          layerDoc.data().locations.map((location, index) => (
            <ListItem key={`${layerDoc.id}-${index}`}>
              <ListItemText>{location.name}</ListItemText>
              <IconButton
                onClick={() => {
                  removeLocationFromLayer(layerDoc.ref, location);
                }}
              >
                <Clear />
              </IconButton>
            </ListItem>
          ))
        ) : (
          <ListItem key={`${layerDoc.id}-empty`}>
            <ListItemText
              css={css`
                font-style: italic;
              `}
            >
              No locations added
            </ListItemText>
          </ListItem>
        )}
      </List>
    </div>
  );
}

const NEW_LAYER = Symbol('NEW_LAYER');

interface ModifyLayerModalProps {
  layerToModify: DocumentSnapshot<Layer> | typeof NEW_LAYER | undefined;
  modifyLayerAction: ModifyLayerAction;
  setLayerToModify: (
    layer: DocumentSnapshot<Layer> | typeof NEW_LAYER | undefined
  ) => void;
  setSelectedLayer: (layer: DocumentSnapshot<Layer>) => void;
  userId: string;
}

function EditLayerForm({
  layerToModify,
  setLayerToModify,
  setSelectedLayer,
  userId
}: ModifyLayerModalProps) {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm<AddLayerForm>();

  const submitAddLayer: SubmitHandler<AddLayerForm> = useCallback(
    async (data) => {
      if (layerToModify !== undefined) {
        setSelectedLayer(
          layerToModify === NEW_LAYER
            ? await getDoc(
                await addLayer({
                  creatorId: userId,
                  name: data.layerName,
                  locations: [],
                  visibility: true,
                  color: ['red', 'orange', 'yellow', 'green', 'blue', 'purple'][
                    (Math.random() * 6) | 0
                  ]
                })
              )
            : updateLayer(layerToModify, data.layerName)
        );
      }
      setLayerToModify(undefined);
    },
    [userId, setSelectedLayer, setLayerToModify, layerToModify]
  );

  return (
    <React.Fragment>
      <Typography>
        {layerToModify === NEW_LAYER ? 'Add a new layer' : 'Update layer'}
      </Typography>
      {layerToModify === undefined ? null : (
        <form
          onSubmit={handleSubmit(submitAddLayer)}
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Controller
            control={control}
            name="layerName"
            render={({ field }) => <TextField {...field} />}
            rules={{ required: true }}
            defaultValue={
              layerToModify === NEW_LAYER ? '' : layerToModify.data()?.name
            }
          />
          {errors.layerName && <p>{errors.layerName.message}</p>}
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <CircularProgress />
            ) : layerToModify === NEW_LAYER ? (
              'Add layer'
            ) : (
              'Update layer'
            )}
          </Button>
        </form>
      )}
    </React.Fragment>
  );
}

const POINT_REGEX = /POINT \((.+)\)/;

function parseWktPoint(wktPoint: string): Coordinate | null {
  const matched = wktPoint.match(POINT_REGEX);
  const split =
    matched &&
    matched[1]
      .split(' ')
      .slice(0, 2)
      .map((point) => point.trim());
  return split && { lng: parseFloat(split[0]), lat: parseFloat(split[1]) };
}

function parseImportRow(row: string) {
  const [wktPoint, name, description] = row
    .split(',')
    .map((elem) => elem.trim());
  const parsedPoint = parseWktPoint(wktPoint);
  if (parsedPoint) {
    return {
      point: parsedPoint,
      name,
      description
    };
  }
  return null;
}

function ImportLocationsForm({
  layerToModify,
  setLayerToModify,
  setSelectedLayer,
  userId
}: ModifyLayerModalProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting }
  } = useForm<ImportLocationsForm>();
  const map = useRecoilValue(mapState);
  const googleMaps = useRecoilValue(googleMapsState);

  const submitImportLocations: SubmitHandler<ImportLocationsForm> = useCallback(
    async (data) => {
      if (layerToModify !== undefined && layerToModify !== NEW_LAYER) {
        const { csvFile } = data;
        if (csvFile.length !== 1) {
          console.warn(
            `Unexpected number of file inputs for import: ${csvFile.length}`
          );
        } else {
          const reader = new FileReader();
          reader.onloadend = async function (evt) {
            const linesWithHeader = String(evt.target?.result).split(/\r?\n/);
            const lines = linesWithHeader.slice(1);

            const parsedRows = lines
              .map(parseImportRow)
              .filter(notUndefinedOrNull);

            if (googleMaps && map) {
              const placesService = new googleMaps.maps.places.PlacesService(
                map
              );

              const locationsWithName = await Promise.all(
                parsedRows
                  .filter((location) => !!location.name)
                  .map(async (row): Promise<Location> => {
                    const nearbySearchResult = await new Promise<
                      google.maps.places.PlaceResult[] | null
                    >((resolve) => {
                      placesService.nearbySearch(
                        {
                          location: new googleMaps.maps.LatLng(
                            row.point.lat,
                            row.point.lng
                          ),
                          radius: 500,
                          keyword: row.name
                        },
                        (result) => resolve(result)
                      );
                    });
                    const topResult = (nearbySearchResult || [])[0];
                    return {
                      ...{
                        coordinates: { lat: row.point.lat, lng: row.point.lng },
                        name: row.name
                      },
                      ...(topResult?.place_id
                        ? {
                            placeId: topResult?.place_id
                          }
                        : {})
                    };
                  })
              );
              const locationsWithoutName = parsedRows
                .filter((location) => !location.name)
                .map(
                  (row): Location => ({
                    coordinates: { lat: row.point.lat, lng: row.point.lng }
                  })
                );
              const allLocations = [
                ...locationsWithName,
                ...locationsWithoutName
              ];
              allLocations.forEach((location) => {
                addLocationToLayer(layerToModify.ref, location);
              });
            }
          };
          reader.readAsText(csvFile[0]);
        }
      }
      setLayerToModify(undefined);
    },
    [setLayerToModify, layerToModify, googleMaps, map]
  );

  return (
    <React.Fragment>
      <Typography>Add a new layer</Typography>
      {layerToModify === undefined ? null : (
        <form
          onSubmit={handleSubmit(submitImportLocations)}
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <input
            type="file"
            {...register('csvFile')}
            name="csvFile"
            required
            accept=".csv"
          />
          {errors.csvFile && <p>{errors.csvFile.message}</p>}
          <Button type="submit" disabled={isSubmitting}>
            Import
          </Button>
        </form>
      )}
    </React.Fragment>
  );
}

function ModifyLayerModal(props: ModifyLayerModalProps) {
  const { layerToModify, setLayerToModify, modifyLayerAction } = props;
  return (
    <Modal
      open={layerToModify !== undefined}
      onClose={() => {
        setLayerToModify(undefined);
      }}
    >
      <Box
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          padding: 16px;
        `}
      >
        {layerToModify === undefined ? null : modifyLayerAction ===
          ModifyLayerAction.EDIT_LAYER ? (
          <EditLayerForm {...props} />
        ) : (
          modifyLayerAction === ModifyLayerAction.IMPORT_LOCATIONS && (
            <ImportLocationsForm {...props} />
          )
        )}
      </Box>
    </Modal>
  );
}

export function LayerSelector({
  selectedLayer,
  setSelectedLayer
}: {
  selectedLayer: DocumentSnapshot<Layer> | undefined;
  setSelectedLayer: (layer: DocumentSnapshot<Layer> | undefined) => void;
}) {
  const authState = useContext(AuthContext);
  const [layerToModify, setLayerToModify] = useState<
    DocumentSnapshot<Layer> | typeof NEW_LAYER | undefined
  >();
  const [modifyLayerAction, setModifyLayerAction] = useState<ModifyLayerAction>(
    ModifyLayerAction.EDIT_LAYER
  );
  const layersCollection = useLayersCollection({
    userId:
      authState.status === AuthStatus.LOGGED_IN ? authState.user.uid : undefined
  });

  useEffect(() => {
    if (!selectedLayer && layersCollection && layersCollection.length > 0) {
      setSelectedLayer(layersCollection[0]);
    }
  }, [selectedLayer, setSelectedLayer, layersCollection]);

  if (authState.status === AuthStatus.LOGGED_OUT) {
    return <Typography>Log in to add layers</Typography>;
  }

  return (
    <React.Fragment>
      {authState.status === AuthStatus.LOGGED_IN && (
        <ModifyLayerModal
          layerToModify={layerToModify}
          modifyLayerAction={modifyLayerAction}
          setSelectedLayer={setSelectedLayer}
          setLayerToModify={setLayerToModify}
          userId={authState.user.uid}
        />
      )}
      <List dense>
        {layersCollection === undefined ? (
          <CircularProgress key="loadingSpinner" />
        ) : (
          layersCollection.map((layerDoc) => (
            <LayerItem
              layerDoc={layerDoc}
              selectedLayer={selectedLayer}
              setSelectedLayer={setSelectedLayer}
              setLayerToModify={setLayerToModify}
              setModifyLayerAction={setModifyLayerAction}
              key={layerDoc.id}
            />
          ))
        )}
        <ListItem disablePadding key="addLayer">
          <ListItemButton
            onClick={() => {
              setModifyLayerAction(ModifyLayerAction.EDIT_LAYER);
              setLayerToModify(NEW_LAYER);
            }}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Add layer" />
          </ListItemButton>
        </ListItem>
      </List>
    </React.Fragment>
  );
}
