/** @jsxImportSource @emotion/react */
import React from 'react';
import './App.css';
import { css, Global } from '@emotion/react';
import { globalCss } from './theme/global';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthPage } from './pages/Auth';
import { HomePage } from './pages/Home';
import { LogoutPage } from './pages/Logout';
import './contexts/AuthContext';
import { AuthProvider } from './contexts/AuthContext';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <div
      className="App"
      css={css`
        height: 100%;
      `}
    >
      <Global styles={[globalCss]} />
      <AuthProvider>
        <RecoilRoot>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/logout" element={<LogoutPage />} />
            </Routes>
          </BrowserRouter>
        </RecoilRoot>
      </AuthProvider>
    </div>
  );
}

export default App;
