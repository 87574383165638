/** @jsxImportSource @emotion/react */
/**
 * Adapted from https://developers.google.com/maps/documentation/javascript/react-map
 */
import { css } from '@emotion/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
// import { GoogleMapsContext } from '../contexts/GoogleMapsContext';
import PlacesAutocomplete, { PlaceType } from './PlacesAutocomplete';
import * as ReactDOMServer from 'react-dom/server';
import { Button, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { addLocationToLayer, useLayersCollection } from '../firestore';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AuthContext, AuthStatus } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { LayerSelector } from './LayerSelector';
import { DocumentSnapshot } from 'firebase/firestore';
import { Add } from '@mui/icons-material';
import { Layer } from '../firestore/types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { googleMapsState, mapState } from '../atoms/googleMaps';

const DRAWER_WIDTH = '320px';

const SEATTLE = {
  lat: 47.60357,
  lng: -122.32945
};

const mapOptions: google.maps.MapOptions = {
  center: SEATTLE,
  zoom: 14,
  mapTypeControl: false,
  fullscreenControl: false
};

const PIN_SVG =
  'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';

function Marker({
  children,
  ...options
}: google.maps.MarkerOptions & {
  children?: React.ReactElement;
  // Override the more permissive map type in MarkerOptions
  map?: google.maps.Map;
}) {
  const [marker, setMarker] = React.useState<google.maps.Marker>();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
  const googleMaps = useRecoilValue(googleMapsState);
  // const { googleMaps } = useContext(GoogleMapsContext);
  const { map } = options;

  useEffect(() => {
    let listener: google.maps.MapsEventListener;

    if (!marker && googleMaps) {
      const marker = new googleMaps.maps.Marker();
      setMarker(marker);

      if (children) {
        const infoWindow = new googleMaps.maps.InfoWindow({
          content: ReactDOMServer.renderToString(children)
        });
        setInfoWindow(infoWindow);
        listener = marker.addListener('click', () => {
          if (infoWindow && marker && map) {
            infoWindow.open({ anchor: marker, map });
          }
        });
      }
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
        if (listener) {
          listener.remove();
        }
      }
      if (infoWindow) {
        infoWindow.close();
      }
    };
  }, [googleMaps, children, marker, map, infoWindow]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
}

function MapElem({
  className,
  children
}: React.PropsWithChildren<{
  className?: string;
}>) {
  const googleMaps = useRecoilValue(googleMapsState);
  const [map, setMap] = useRecoilState(mapState);

  // Instantiate the map
  useEffect(() => {
    if (googleMaps) {
      if (mapRef.current && !map) {
        setMap(new googleMaps.maps.Map(mapRef.current, mapOptions));
      }
    }
  }, [googleMaps, map, setMap]);

  const mapRef = useRef<HTMLDivElement>(null);
  return (
    <React.Fragment>
      <div
        ref={mapRef}
        className={className}
        css={css`
          flex-grow: 1;
        `}
      />
      {children}
    </React.Fragment>
  );
}

export function MapApp() {
  const googleMaps = useRecoilValue(googleMapsState);
  // const { googleMaps } = useContext(GoogleMapsContext);
  const authState = useContext(AuthContext);
  const [autocompleteResult, setAutocompleteResult] =
    useState<PlaceType | null>(null);
  const map = useRecoilValue(mapState);
  const [autocompletePlaceDetailsResult, setAutocompletePlaceDetailsResult] =
    useState<google.maps.places.PlaceResult>();
  const [selectedLayer, setSelectedLayer] = useState<
    DocumentSnapshot<Layer> | undefined
  >();
  const [showLayers, setShowLayers] = useState(false);
  const layers = useLayersCollection({
    userId:
      authState.status === AuthStatus.LOGGED_IN ? authState.user.uid : undefined
  });

  // Handle autocomplete result selected
  useEffect(() => {
    if (googleMaps && map && autocompleteResult) {
      const service = new googleMaps.maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId: autocompleteResult.place_id,
          fields: ['name', 'geometry']
        },
        (result) => {
          if (result) {
            setAutocompletePlaceDetailsResult(result);
            if (result.geometry?.location) {
              map.setCenter(result.geometry.location);
            }
          }
        }
      );
    }
  }, [googleMaps, map, autocompletePlaceDetailsResult, autocompleteResult]);

  useEffect(() => {
    if (!autocompleteResult && autocompletePlaceDetailsResult) {
      setAutocompletePlaceDetailsResult(undefined);
    }
  }, [autocompletePlaceDetailsResult, autocompleteResult]);

  const drawerContents = (
    <List
      css={css`
        width: 100%;
      `}
    >
      <LayerSelector
        selectedLayer={selectedLayer}
        setSelectedLayer={setSelectedLayer}
      />
      <Divider />
      <ListItem disablePadding>
        {authState.status === AuthStatus.LOGGED_IN ? (
          <ListItemButton href="/logout" LinkComponent={Link}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        ) : (
          authState.status === AuthStatus.LOGGED_OUT && (
            <ListItemButton href="/auth" LinkComponent={Link}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </ListItemButton>
          )
        )}
      </ListItem>
    </List>
  );

  return (
    <div
      css={css`
        height: 100%;
        display: flex;
      `}
    >
      <Drawer
        sx={{ display: { md: 'none' } }}
        variant="temporary"
        open={showLayers}
        onClose={() => {
          setShowLayers(false);
        }}
        // css={css`
        //   min-width: 240px;
        //   max-width: 320px;
        // `}
      >
        {drawerContents}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          width: DRAWER_WIDTH,
          '& .MuiDrawer-paper': { width: DRAWER_WIDTH }
        }}
        variant="permanent"
        // css={css`
        //   min-width: 240px;
        //   max-width: 320px;
        // `}
      >
        {drawerContents}
      </Drawer>
      <div
        css={css`
          height: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            z-index: 100;
            background-color: white;
            margin: 16px;
            padding: 16px;
            text-align: left;
          `}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Typography variant="overline">
              Selected layer: {selectedLayer?.data()?.name}
            </Typography>
            <Button
              sx={{ display: { md: 'none' } }}
              onClick={() => {
                setShowLayers(!showLayers);
              }}
            >
              {showLayers ? 'Hide' : 'Show'} layers
            </Button>
          </div>
          <div
            css={css`
              display: flex;
            `}
          >
            <PlacesAutocomplete
              autocompletePrediction={autocompleteResult}
              setAutocompletePrediction={setAutocompleteResult}
            />
            <IconButton
              disabled={
                !autocompleteResult ||
                !autocompletePlaceDetailsResult ||
                !selectedLayer
              }
              onClick={() => {
                if (
                  selectedLayer &&
                  autocompleteResult &&
                  autocompletePlaceDetailsResult?.geometry?.location
                ) {
                  addLocationToLayer(selectedLayer.ref, {
                    name: autocompleteResult.structured_formatting.main_text,
                    placeId: autocompleteResult.place_id,
                    coordinates: {
                      lat: autocompletePlaceDetailsResult.geometry.location.lat(),
                      lng: autocompletePlaceDetailsResult.geometry.location.lng()
                    }
                  });
                  setAutocompleteResult(null);
                }
              }}
            >
              <Add />
            </IconButton>
          </div>
        </div>
        <MapElem
          css={css`
            flex-grow: 1;
          `}
        >
          {autocompletePlaceDetailsResult?.geometry?.location && (
            <Marker
              key={autocompletePlaceDetailsResult.place_id}
              map={map}
              position={autocompletePlaceDetailsResult.geometry.location}
            >
              <h1>Test info window</h1>
            </Marker>
          )}
          {layers?.map((layerDoc) => {
            const layer = layerDoc.data();
            return layer.visibility
              ? layer.locations.map((location, index) => (
                  <Marker
                    key={`${layerDoc.id}-${index}`}
                    map={map}
                    position={location.coordinates}
                    // https://stackoverflow.com/a/23163930
                    icon={{
                      // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
                      path: PIN_SVG,
                      anchor: new google.maps.Point(12, 17),
                      fillOpacity: 1,
                      fillColor: layer.color,
                      strokeWeight: 2,
                      strokeColor: 'white',
                      scale: 2,
                      labelOrigin: new google.maps.Point(12, 9)
                    }}
                  />
                ))
              : null;
          })}
        </MapElem>
      </div>
    </div>
  );
}
