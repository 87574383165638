/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

export function Page({
  children,
  className
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={className}
      css={css`
        height: 100%;
      `}
    >
      {children}
    </div>
  );
}
