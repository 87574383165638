export function getEnv(varName: string, varValue: string | undefined) {
  if (!varValue) {
    throw new Error(`Missing environment variable: ${varName}`);
  }
  return varValue;
}

export function notUndefinedOrNull<T>(elem: T | undefined | null): elem is T {
  return !!elem;
}
