import { getAuth } from 'firebase/auth';
import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext, AuthStatus } from '../contexts/AuthContext';

export function LogoutPage() {
  const authState = useContext(AuthContext);

  useEffect(() => {
    if (authState.status === AuthStatus.LOGGED_IN) {
      const auth = getAuth();
      auth.signOut();
    }
  }, [authState]);

  return authState.status === AuthStatus.LOGGED_OUT ? (
    <Navigate to={'/'} />
  ) : (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
}
