/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { MapApp } from '../components/MapApp';
import { Page } from '../components/Page';

export function HomePage() {
  return (
    <Page>
      <div
        css={css`
          height: 100%;
        `}
      >
        <MapApp />
      </div>
    </Page>
  );
}
