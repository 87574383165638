import { css } from '@emotion/react';
import { styleReset } from './styleReset';

export const globalCss = css`
  ${styleReset};
  html,
  body,
  #root {
    height: 100%; /* minimum full page screen */
    scroll-behavior: smooth;
  }
  style {
    display: none !important;
  }
`;
