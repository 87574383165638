import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import React, { useEffect, useState } from 'react';

export enum AuthStatus {
  LOADING = 'LOADING',
  LOGGED_OUT = 'LOGGED_OUT',
  LOGGED_IN = 'LOGGED_IN'
}

type AuthState =
  | {
      status: AuthStatus.LOADING;
      user: undefined;
    }
  | {
      status: AuthStatus.LOGGED_OUT;
      user: undefined;
    }
  | {
      status: AuthStatus.LOGGED_IN;
      user: User;
    };

const INITIAL_AUTH_STATE: AuthState = {
  status: AuthStatus.LOADING,
  user: undefined
};

export const AuthContext = React.createContext<AuthState>(INITIAL_AUTH_STATE);

export function AuthProvider({ children }: React.PropsWithChildren) {
  const [authState, setAuthState] = useState(INITIAL_AUTH_STATE);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthState({ status: AuthStatus.LOGGED_IN, user });
      } else {
        setAuthState({ status: AuthStatus.LOGGED_OUT, user: undefined });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}
