/** @jsxImportSource @emotion/react */
import * as firebaseui from 'firebaseui';
import { css } from '@emotion/react';
import { getAuth, EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { app } from '../helpers/firebase';
import React, { useContext, useEffect } from 'react';
import { Page } from '../components/Page';
import { AuthContext, AuthStatus } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const uiConfig: firebaseui.auth.Config = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    }
  },
  signInOptions: [
    {
      provider: EmailAuthProvider.PROVIDER_ID
    },
    {
      provider: GoogleAuthProvider.PROVIDER_ID
    }
  ],
  signInFlow: 'popup',
  signInSuccessUrl: '/'
  // Other config options...
};

const ui = new firebaseui.auth.AuthUI(getAuth(app));

export function AuthPage() {
  const authState = useContext(AuthContext);

  useEffect(() => {
    if (authState.status === AuthStatus.LOGGED_OUT) {
      ui.start('#firebaseui-auth-container', uiConfig);
    }
  }, [authState]);

  if (authState.status === AuthStatus.LOGGED_IN) {
    return <Navigate to="/" />;
  }

  return (
    <Page
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      {authState.status === AuthStatus.LOADING && <CircularProgress />}
      <div
        id="firebaseui-auth-container"
        css={
          authState.status !== AuthStatus.LOGGED_OUT &&
          css`
            display: none;
          `
        }
      ></div>
    </Page>
  );
}
