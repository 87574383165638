import { getEnv } from '../helpers/utility';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  getFirestore,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  updateDoc,
  where
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { useEffect, useState } from 'react';
import { Layer, Location } from './types';

// Do NOT update this manually.
// See: https://firebase.google.com/docs/web/learn-more#config-object
const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: getEnv(
    'REACT_APP_FIREBASE_API_KEY',
    process.env.REACT_APP_FIREBASE_API_KEY
  ),
  authDomain: 'mymaps-64960.firebaseapp.com',
  projectId: 'mymaps-64960',
  storageBucket: 'mymaps-64960.appspot.com',
  messagingSenderId: '868686278164',
  appId: '1:868686278164:web:ecc9a31f237ecd95c8c101',
  measurementId: 'G-W66019ZVR1'
};

enum Collection {
  layers = 'layers'
}

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);

const analytics = getAnalytics(app);
const db = getFirestore(app);

const layerConverter: FirestoreDataConverter<Layer> = {
  toFirestore: (layer: Layer) => {
    return layer;
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ) => {
    return snapshot.data() as Layer;
  }
};

const layersCollectionRef = collection(db, Collection.layers).withConverter(
  layerConverter
);

export function useLayersCollection({ userId }: { userId?: string }) {
  const [layersCollection, setLayersCollection] = useState<
    QueryDocumentSnapshot<Layer>[] | undefined
  >();

  useEffect(() => {
    if (!userId) {
      setLayersCollection(undefined);
      return undefined;
    }

    const unsubscribe = onSnapshot(
      query(layersCollectionRef, where('creatorId', '==', userId)),
      (layerCollection) => {
        setLayersCollection(layerCollection.docs);
      }
    );
    return unsubscribe;
  }, [userId]);

  return layersCollection;
}

export function addLayer(layer: Layer) {
  return addDoc(layersCollectionRef, layer);
}

export function updateLayer(layerDoc: DocumentSnapshot<Layer>, name: string) {
  updateDoc(layerDoc.ref, { name });
  return layerDoc;
}

export function addLocationToLayer(
  layerRef: DocumentReference<Layer>,
  location: Location
) {
  updateDoc(layerRef, {
    locations: arrayUnion(location)
  });
}

export function removeLocationFromLayer(
  layerRef: DocumentReference<Layer>,
  location: Location
) {
  updateDoc(layerRef, {
    locations: arrayRemove(location)
  });
}

export function toggleLayerVisibility(layer: DocumentSnapshot<Layer>) {
  updateDoc(layer.ref, {
    visibility: !layer.data()?.visibility
  });
}
