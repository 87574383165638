// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDJ5VnTQ_4b2fVsaPeO0BI63IXVYAX6H8Q',
  authDomain: 'mymaps-64960.firebaseapp.com',
  projectId: 'mymaps-64960',
  storageBucket: 'mymaps-64960.appspot.com',
  messagingSenderId: '868686278164',
  appId: '1:868686278164:web:ecc9a31f237ecd95c8c101',
  measurementId: 'G-W66019ZVR1'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
