/**
 * Adapted from https://mui.com/material-ui/react-autocomplete/#google-maps-place
 */

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { googleMapsState } from '../atoms/googleMaps';

const autocompleteService: {
  current: null | google.maps.places.AutocompleteService;
} = { current: null };

// Aliasing this type for easier use.
export type PlaceType = google.maps.places.AutocompletePrediction;

export default function PlacesAutocomplete({
  autocompletePrediction,
  setAutocompletePrediction
}: {
  autocompletePrediction: PlaceType | null;
  setAutocompletePrediction: (prediction: PlaceType | null) => void;
}) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const google = useRecoilValue(googleMapsState);

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (
            results?: google.maps.places.AutocompletePrediction[] | null
          ) => void
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback);
        },
        200
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (google) {
      if (!autocompleteService.current) {
        autocompleteService.current =
          new google.maps.places.AutocompleteService();
      }
      if (!autocompleteService.current) {
        return undefined;
      }

      if (inputValue === '') {
        setOptions(autocompletePrediction ? [autocompletePrediction] : []);
        return undefined;
      }

      fetch(
        { input: inputValue },
        (results?: google.maps.places.AutocompletePrediction[] | null) => {
          if (active) {
            let newOptions: readonly PlaceType[] = [];

            if (autocompletePrediction) {
              newOptions = [autocompletePrediction];
            }

            if (results) {
              newOptions = [...newOptions, ...results];
            }

            setOptions(newOptions);
          }
        }
      );
    }

    return () => {
      active = false;
    };
  }, [autocompletePrediction, inputValue, fetch, google]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={autocompletePrediction}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setAutocompletePrediction(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search for a location" fullWidth />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
