import { atom } from 'recoil';
import { Loader } from '@googlemaps/js-api-loader';
import { AtomKey } from '.';
import { getEnv } from '../helpers/utility';

const loader = new Loader({
  apiKey: getEnv(
    'REACT_APP_GOOGLE_MAPS_API_KEY',
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  ),
  version: 'weekly',
  libraries: ['places']
});

export const googleMapsState = atom<typeof google | undefined>({
  key: AtomKey.GOOGLE_MAPS,
  default: undefined,
  effects: [
    ({ setSelf }) => {
      loader.load().then((google) => {
        setSelf(google);
      });
    }
  ]
});

export const mapState = atom<google.maps.Map | undefined>({
  key: AtomKey.MAP,
  default: undefined,
  // Necessary for google maps to work properly
  dangerouslyAllowMutability: true
});
